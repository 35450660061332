<script setup lang="ts">
import filter from "lodash/filter";
import AbandonedCart from "@/models/AbandonedCart";
import RegFlowStep from "@/models/RegFlowStep";
import { MAPPED_ROUTE_NAMES, type RouteName } from "@/constants/routes";
import { SIGNUP_USED_FLAG } from "@/constants/signup";

const onboardingStore = useOnboardingStore();
const route = useRoute();
const router = useRouter();
const shouldShowSignup = ref<string | boolean>(false);
const hiddenRoutes = [
  MAPPED_ROUTE_NAMES.LOGIN,
  MAPPED_ROUTE_NAMES.SUCCESS,
  MAPPED_ROUTE_NAMES.PROCESSING,
];

const abandonedCart = computed(() => new AbandonedCart(onboardingStore.$state));

const isVisible = computed(
  () =>
    !hiddenRoutes.includes(route.name as RouteName) &&
    !(route.name === "menus" && !abandonedCart.value.hasPlan())
);

const steps = computed<RegFlowStep[]>(() => {
  const blockOnSignup = () => route.name !== MAPPED_ROUTE_NAMES.SIGNUP;

  return filter([
    shouldShowSignup.value ? new RegFlowStep("signup", 0, () => true) : null,
    new RegFlowStep("plans", 1, blockOnSignup),
    new RegFlowStep("menu", 2, blockOnSignup),
    new RegFlowStep(
      "checkout",
      3,
      () => (onboardingStore.isCheckoutStepAvailable ?? false) && blockOnSignup()
    ),
  ], Boolean) as RegFlowStep[];
});

onMounted(() => {
  shouldShowSignup.value =
    window.localStorage.getItem(SIGNUP_USED_FLAG) || route.name === "signup";
});
</script>
<template>
  <div
    v-if="isVisible"
    class="tw-pt-1.5 md:tw-pt-3 tw-pb-0.5 md:tw-pb-2 tw-shadow-nl-5 tw-text-[15px] tw-relative tw-z-50 tw-bg-nl-yellow-100"
  >
    <a
      href="/"
      class="tw-hidden lg:tw-block tw-absolute tw-top-[23px] tw-left-[33px] tw-w-[160px]"
    >
      <img src="/assets/images/signup/logo.svg" alt="Nurture Life" />
    </a>

    <ul class="tw-flex tw-justify-center tw-gap-x-[10px] md:tw-gap-x-[50px]">
      <li v-for="(step, index) in steps" :key="step.name">
        <a
          href="#"
          class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-0.5 tw-w-[88px]"
          @click.prevent="step.navigate(router)"
        >
          <span v-if="step.isComplete(route, !!abandonedCart?.hasPlan())">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="15"
                cy="15"
                r="13.5"
                fill="#007D4D"
                stroke="#007D4D"
                stroke-width="3"
              />
              <path
                d="M10 16.7188C11.6667 18.3438 12.9167 22 14.5833 22C16.25 22 18.3333 11.8438 20 9"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </span>
          <span
            v-else
            class="tw-flex tw-items-center tw-justify-center tw-font-bold tw-h-[30px] tw-w-[30px] tw-rounded-full tw-border-[3px]"
            :class="{
              // complete: tw-bg-nl-green-400 tw-border-transparent tw-text-white
              // incomplete: tw-bg-transparent tw-border-nl-green-100 tw-nl-green-100
              'tw-border-transparent tw-text-white tw-bg-nl-green-400':
                step.isComplete(route, !!abandonedCart?.hasPlan()), // to be added when next steps are redesigned
              'tw-bg-nl-green-400 tw-border-transparent tw-text-white':
                step.isCurrent(route),
              'tw-bg-transparent tw-border-nl-green-100 tw-nl-green-100':
                step.isUpcoming(route),
              'tw-bg-transparent tw-border-nl-green-100 tw-text-nl-green-100':
                !step.isCurrent(route),
            }"
          >{{ index + 1 }}</span
          >
          <span
            class="tw-uppercase"
            :class="{
              'tw-font-bold': step.isCurrent(route),
            }"
          >{{ step.name }}</span
          >
        </a>
      </li>
    </ul>
  </div>
</template>